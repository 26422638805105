<template>
  <div>
    <div class="mass-action-settings">
      <el-form
        size="small"
        ref="editForm"
        label-position="top"
      >
        <el-form-item label="Период действия">
          С
          <el-date-picker
            v-model="actionData.date_start"
            style="width: 150px"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            :picker-options="{firstDayOfWeek: 1,}"
          >
          </el-date-picker>
          &nbsp;по&nbsp;
          <el-date-picker
            v-model="actionData.date_end"
            style="width: 150px"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            :picker-options="{firstDayOfWeek: 1,}"
          >
          </el-date-picker>
        </el-form-item>

      </el-form>


      <el-popconfirm
        style=""
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить даты?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Изменить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
      :error-props="['id', 'info.last_name', 'info.first_name', 'info.middle_name', 'info.company']"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-update-info-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.date_end || !this.actionData.date_end;
    }
  },

  data() {
    return {
      actionData: {
        date_start: null,
        date_end: null,
      }
    }
  },
  methods: {},
}
</script>
